import React from 'react';
import styled from 'styled-components';
import FeaturePhonePNG from '../../../Assets/featurePhone.png'
import PurpleStarPNG from '../../../Assets/purpleFetureStar.png'
import RedStarPNG from '../../../Assets/redFeatureStar.png';
import PurpleLinePNG from '../../../Assets/feturePurpleLine.png';
import PinkLinePNG from '../../../Assets/pinkFetureLine.png'
import RedLinePNG from '../../../Assets/fetureRedLine.png'

const FeatureSection = () => {
    return (
        <Container>
            <Section1>
                <FeatureData>
                    <FeatureHeading><img src={PurpleStarPNG} style={{ height: "27px", width: "27px", position: "absolute", top: "-38px", left: "0" }} />Photo Album Creat<span style={{ background: "#DED6F7", borderRadius: "50%" }}>ion</span> </FeatureHeading>
                    <FeatureDescription>Create Memorable Albums to Capture the Essence of Every Event</FeatureDescription>
                </FeatureData>
                <FeatureData >
                    <FeatureHeading><img src={PurpleLinePNG} style={{ height: "37px", width: "37px", position: "absolute", top: "-21px", right: "0" }} /><span style={{ background: "#FBACBF", borderRadius: "83%" }}>Loc</span>k Unlock Albums</FeatureHeading>
                    <FeatureDescription>Secure Your Albums: Lock and Unlock with Custom Reasons for Enhanced Privacy</FeatureDescription>
                </FeatureData>
                <FeatureData>
                    <FeatureHeading>Subscribe Best Pla<span style={{ background: "#DED6F7", borderRadius: "50%" }}>ns&nbsp;</span></FeatureHeading>
                    <FeatureDescription>Flexible Subscriptions: Tailor Your Experience with Plans That Fit Your Needs</FeatureDescription>
                </FeatureData>
            </Section1>
            <Section2>
                <LiftLineSection>

                    <LineLeft1 >
                        <CrossLine></CrossLine>
                        <div style={{ width: "155px", height: "2px", borderTop: "2px dashed #F62F60" }}></div>
                    </LineLeft1>
                    <LineLeft2 >
                        <StraightLine />

                    </LineLeft2>
                    <LeftLine3 >
                        <CrossLine2></CrossLine2>
                        <StraightLine2 />
                    </LeftLine3>
                </LiftLineSection>
                <MobileImage src={FeaturePhonePNG} />
                <RightLineSection>
                    <RightLine1 >
                        <CrossLine style={{ transform: "rotate(-24deg)", transformOrigin: "left" }}></CrossLine>
                        <div style={{ width: "155px", height: "2px", borderTop: "2px dashed #F62F60" }}></div>
                    </RightLine1>
                    <RightLine2 >
                        <StraightRightLine />

                    </RightLine2>
                    <RightLine3 >
                        <CrossRightLine2 />
                        <StraightRightLine2 />
                    </RightLine3>
                </RightLineSection>
            </Section2>
            <Section3>
                <FeatureData>
                    <FeatureHeading><img src={PinkLinePNG} style={{ height: "57px", width: "62px", position: "absolute", top: "-35px", right: "0" }} /><span style={{ background: "#FBACBF", borderRadius: "83%" }}>&nbsp;Ev</span>ent Code Sharing</FeatureHeading>
                    <FeatureDescription>Seamless Sharing: Use Event Codes to Easily Distribute Images to Your Clients</FeatureDescription>
                </FeatureData>
                <FeatureData >
                    <FeatureHeading><img src={RedStarPNG} style={{ height: "22px", width: "22px", position: "absolute", top: "-35px", left: "0" }} />Favorite Photos Anyti<span style={{ background: "#DED6F7", borderRadius: "80%" }}>me</span></FeatureHeading>
                    <FeatureDescription>Personalize Your Experience: Like and Save Your Favorite Images with Ease</FeatureDescription>
                </FeatureData>
                <FeatureData>
                    <FeatureHeading><span style={{ background: "#FBACBF", borderRadius: "83%" }}>&nbsp;Sc</span>an Face to Show Photos</FeatureHeading>
                    <FeatureDescription>Instant Discovery: Use Face Scan Technology to Effortlessly Find Your Photos<img src={RedLinePNG} style={{ height: "36px", width: "36px", position: "absolute", bottom: "-35px", right: "0" }} /></FeatureDescription>
                </FeatureData>
            </Section3>
        </Container>
    )
}

export default FeatureSection;

const Container = styled.div`
display:flex;
margin-top:60px;

@media (max-width:1200px){
flex-direction:column;
align-items:center;
justify-content:center;
}
`;

const Section1 = styled.div`
width:30%;
display:flex;
flex-direction:column;
align-items:center;
justify-content:space-between;


@media (max-width:1200px){
width:100%;
    min-height:400px;
}
`;

const Section2 = styled.div`
width:40%;
display:flex;
align-items:center;
justify-content:center;
position:relative;
height:596px;

@media (max-width:1200px){
width:100%;
}
`;

const Section3 = styled.div`
width:30%;
display:flex;
flex-direction:column;
align-items:center;
justify-content:space-between;


@media (max-width:1200px){
width:100%;
    min-height:400px;
    margin-top:40px;
}
`;

const MobileImage = styled.img`
width:60%;
max-width:300px;
height :600px;
@media (max-width:1200px){

width:100%;

  
}
`;

const FeatureData = styled.div`
max-width:300px;
background-color:#F8EDFA;
z-index:100;


@media (max-width:1200px){
  margin:30px 0px;

}
`;

const FeatureHeading = styled.div`
position:relative;
font-family: Urbanist;
position:relative;
font-size: 26px;
font-weight: 500;
`;

const FeatureDescription = styled.div`
position:relative;
font-family: Urbanist;
font-size: 16px;
font-weight: 400;

`;

const LineLeft1 = styled.div`
position:absolute;
top:148px;
right:3px;
display:flex;

`;
const RightLine1 = styled.div`
position:absolute;
top:148px;
display:flex;
flex-direction:row-reverse;
`;

const LineLeft2 = styled.div`
position:absolute;
top:300px;
right:3px;
display:flex;

`;
const RightLine2 = styled.div`
position:absolute;
top:300px;
display:flex;

`;
const LiftLineSection = styled.div`
position:relative;
width:20%;
height:100%;


@media (max-width:1200px){
 display:none;
}
`;

const RightLineSection = styled.div`
position:relative;
width:20%;
height:100%;

@media (max-width:1200px){
 display:none;
}
`;


const CrossLine = styled.div`
    width:208px;
    height:2px;
    border-top:2px dashed #F62F60;
    transform-origin:right;
    transform:rotate(20deg);

    @media (max-width:1400px){
      width:200px;
      
    }
    @media (max-width:1200px){
      width:100px;

    }
`;

const CrossLine2 = styled.div`
 width:200px;
    height:2px;
    border-top:2px dashed #F62F60;
    transform-origin:right;
    transform:rotate(-34deg);
`;

const CrossRightLine2 = styled.div`
 width:200px;
    height:2px;
    border-top:2px dashed #F62F60;
    transform-origin:left;
    transform:rotate(34deg);
`;

const StraightLine = styled.div`
    width:300px;
    height:2px;
    border-top:2px dashed #5934D5;


    @media (max-width:1400px){
      width:250px;
      
    }
    @media (max-width:1200px){
      width:150px;

    }
`;

const StraightRightLine = styled.div`
    width:300px;
    height:2px;
    border-top:2px dashed #5934D5;


    @media (max-width:1400px){
      width:250px;
      
    }
    @media (max-width:1200px){
      width:150px;

    }
`;

const LeftLine3 = styled.div`
position:absolute;
top:448px;
right:3px;
display:flex;
flex-direction:row-reverse;

`;

const RightLine3 = styled.div`
position:absolute;
top:448px;

display:flex;

`;

const StraightLine2 = styled.div`
 width:200px;
    height:2px;
    border-top:2px dashed #F62F60;
    position:absolute;
    top:114px;
    right:166px;

    @media (max-width:100px){
      width:100px;
      
    }
    @media (max-width:1400px){
      width:50px;
      
    }
   
`;

const StraightRightLine2 = styled.div`
 width:200px;
    height:2px;
    border-top:2px dashed #F62F60;
    position:absolute;
    top:114px;
    left:166px;

    @media (max-width:100px){
      width:100px;
      
    }
    @media (max-width:1400px){
      width:50px;
      
    }
   
`;
// width: "140px", height: "2px", borderTop: "2px dashed pink", transformOrigin: "right", transform: "rotate(14deg)" 


const TextColorDiv = styled.div`
position: absolute;
width:50px;
height:50px;
border-radius:50%;
`;