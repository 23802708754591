import React, { useState, useRef, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useInView } from 'react-intersection-observer'
import AboutUsPNG from '../../../Assets/aboutUs.png'
import AboutUsHeadingPNG from '../../../Assets/aboutUsHeadingArrow.png'
import AboutUsButtonArrowPNG from '../../../Assets/aboutUsArrow.png'
// Keyframes for the image animation (left to right)
const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`

// Keyframes for the text animation (right to left)
const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`

// Styled component for the About Us container
const AboutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: #f8edfa;
  border-radius: 12px;
  width: 80%;

  justify-content: space-evenly;
  margin: 0 auto;
  padding-top: 100px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

// Styled component for the image container
const ImageContainer = styled.div`
  width: 25%;
  max-width: 250px;
  opacity: 0; /* Hidden initially */
  animation: ${(props) => (props.inView ? slideInFromLeft : 'none')} 1s forwards;
  background-color: #edcaf1;
  position: relative;
  border-radius: 20px;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`

// Styled component for the text container
const TextContainer = styled.div`
  width: 60%;
  opacity: 0; /* Hidden initially */
  animation: ${(props) => (props.inView ? slideInFromRight : 'none')} 1s
    forwards;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 30px;
  }
`

// Styled component for the expanding text
const ExpandableTextWrapper = styled.div`
  max-height: ${(props) => (props.expanded ? `${props.height}px` : '250px')};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  position: relative;
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 400;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: ${(props) => (props.expanded ? '0' : '100%')};
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 60%,
      #f8edfa 100%
    );
  }
`

// Styled button
const MoreButton = styled.button`
  background: linear-gradient(360deg, #7a11a1 0%, #c62bc9 100%);
  color: #ffffff;
  font-family: Urbanist;
  font-size: 24px;
  font-weight: 500;
  box-shadow: 0px 4px 14px 0px #86169680;
  border: none;
  width: 192px;
  height: 67px;
  cursor: pointer;
  margin: 20px 0px;
  margin-top: 50px;
  border-radius: 46px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #9d29b8;
  }
`
const HeadingSection = styled.div`
  position: relative;
  max-width: 250px;

  img {
    @media (max-width: 768px) {
      display: none;
    }
  }
`

const Heading = styled.div`
  font-family: Urbanist;
  font-size: 32px;
  font-weight: 600;
  line-height: 45.76px;
  text-align: left;
`

const ButtonContainer = styled.div`
  position: relative;
  max-width: 400px;
  display: flex;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  img {
    @media (max-width: 768px) {
      display: none;
    }
  }
`

const AboutUs = () => {
  const [expanded, setExpanded] = useState(false)
  const [textHeight, setTextHeight] = useState(0)
  const textRef = useRef(null)
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 })

  useEffect(() => {
    if (textRef.current) {
      setTextHeight(textRef.current.scrollHeight)
    }
  }, [expanded])

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const text =
    `At KwikMedia, we’re redefining the way studios and users interact with photography. Our platform empowers studios to create personalized photo albums and effortlessly share them with clients, streamlining the entire photo management process. For users, we offer a seamless experience to like, download, and even use advanced face scan technology to quickly find their own photos in large collections.<br />

Built with a commitment to simplicity, efficiency, and user satisfaction, KwikMedia bridges the gap between professional photographers and their clients, making every moment easily accessible and memorable. Whether you're capturing life’s special events or cherishing everyday memories, KwikMedia is here to help you stay connected and organized with your photos in just a few clicks.<br />

Our mission is to make professional photography more accessible and enjoyable for everyone involved. By providing studios with intuitive tools to manage their albums and giving users a seamless way to access their images, we aim to deliver an unmatched photography experience. With features like flexible subscription plans, secure album locking, and effortless photo sharing through event codes, KwikMedia adapts to the unique needs of both studios and their clients.<br />

We are constantly innovating and evolving our platform to stay ahead of industry trends and exceed our users’ expectations. At KwikMedia, we believe that photography should be more than just a transaction—it should be an experience that brings people closer to the moments that matter most. Our team is dedicated to making that experience as smooth and rewarding as possible.`

  return (
    <AboutContainer ref={ref} >
      <ImageContainer inView={inView}>
        <img
          src={AboutUsPNG}
          alt="Photographer"
          style={{
            width: '100%',
            borderRadius: '20px',
            height: 'auto',
            transform: 'translate(20px, 20px)',
          }}
        />
      </ImageContainer>
      <TextContainer inView={inView}>
        <HeadingSection>
          <img
            src={AboutUsHeadingPNG}
            style={{
              position: 'absolute',
              top: '-14px',
              right: '43px',
              height: '50px',
              width: '80px',
            }}
          />
          <Heading>About Us</Heading>
        </HeadingSection>
        <ExpandableTextWrapper expanded={expanded} height={textHeight}>
          <div ref={textRef}><p>At KwikMedia, we’re redefining the way studios and users interact with photography. Our platform empowers studios to create personalized photo albums and effortlessly share them with clients, streamlining the entire photo management process. For users, we offer a seamless experience to like, download, and even use advanced face scan technology to quickly find their own photos in large collections.</p>

            <p>Built with a commitment to simplicity, efficiency, and user satisfaction, KwikMedia bridges the gap between professional photographers and their clients, making every moment easily accessible and memorable. Whether you're capturing life’s special events or cherishing everyday memories, KwikMedia is here to help you stay connected and organized with your photos in just a few clicks.</p>

            <p>Our mission is to make professional photography more accessible and enjoyable for everyone involved. By providing studios with intuitive tools to manage their albums and giving users a seamless way to access their images, we aim to deliver an unmatched photography experience. With features like flexible subscription plans, secure album locking, and effortless photo sharing through event codes, KwikMedia adapts to the unique needs of both studios and their clients.</p>

            <p>We are constantly innovating and evolving our platform to stay ahead of industry trends and exceed our users’ expectations. At KwikMedia, we believe that photography should be more than just a transaction—it should be an experience that brings people closer to the moments that matter most. Our team is dedicated to making that experience as smooth and rewarding as possible.</p>
          </div>
        </ExpandableTextWrapper>
        <ButtonContainer>
          <img
            src={AboutUsButtonArrowPNG}
            style={{
              position: 'absolute',
              right: '10px',
              top: '-29px',
              height: 'auto',
              width: '200px',
            }}
          />
          <MoreButton onClick={toggleExpanded}>
            {expanded ? 'Show Less' : 'More'}
          </MoreButton>
        </ButtonContainer>
      </TextContainer>
    </AboutContainer>
  )
}

export default AboutUs
