import React from 'react';
import styled from 'styled-components';
import LeftSection from '../../Molecules/HeroSection/LeftSection';
import RotatingProfileCircle from '../../Atoms/HeroSection/TestLottiefy';
import HeroSectionIconPNG from '../../../Assets/heroSectionArrow.png'

const HeroSection = () => {
    return (
        <Container id="home">
            <Section1>
                <LeftSection />
            </Section1>
            <Section2>
                <RotatingProfileCircle />
            </Section2>
            <Section3>
                <HeroSectionArrow src={HeroSectionIconPNG} />
            </Section3>
        </Container>
    )
}

export default HeroSection;

const Container = styled.div`
width:100%;
display:flex;
flex-direction:row;
padding-top:100px;
min-height:500px;
position:relative;
 @media (max-width: 1200px) {
    flex-direction:column;
    padding-top:80px;
    min-height:700px;
    overflow-x: hidden;
    overflow-y: hidden;
 }
 @media (max-width: 700px) {
    min-height:800px;
 }

`;
const Section1 = styled.div`
width:64%;
display:flex;
align-items:center;
justify-content:space-evenly;

 @media (max-width: 1200px) {
    width:100%;
 }
 @media (max-width: 1200px) {
    width:94%;
 }
`;

const Section2 = styled.div`
width:30%;
display:flex;
align-items:center;
justify-content:center;
 @media (max-width: 1200px) {
     width:100%;
     padding-top:50px;
 }
`;

const Section3 = styled.div`
position:absolute;
bottom:0px;
right:35%;
z-index:100;

 @media (max-width: 1200px) {
    display:none;
 }
`;

const HeroSectionArrow = styled.img`
width:180px;
height:auto;
`;