import React from 'react';
import styled, { keyframes } from 'styled-components';
import LogoPNG from '../../../Assets/kwikmedia_icon_black.png';
import Circle11PNG from '../../../Assets/circle11.png';
import Circle12PNG from '../../../Assets/circle12.png';
import Circle13PNG from '../../../Assets/circle13.png';
import Circle14PNG from '../../../Assets/circle14.png';
import Circle21PNG from '../../../Assets/circle21.png';
import Circle22PNG from '../../../Assets/circle22.png';
import Circle31PNG from '../../../Assets/circle31.png';
import Circle32PNG from '../../../Assets/circle32.png';
import Circle33PNG from '../../../Assets/circle33.png';
import Circle34PNG from '../../../Assets/circel34.png';
import KwikMediaWhitePNG from '../../../Assets/kwikmediaLogo.png';
import WorldMapPNG from '../../../Assets/worldmap.png';
import MarkerIconPNG from '../../../Assets/marker.png'
// Animations
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const rotateReverse = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

const rotateReverse1 = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const rotateReverse2 = keyframes`
  from {
    transform: rotate(540deg);
  }
  to {
    transform: rotate(-540deg);
  }
`;

// Main container to hold the entire animation
const MainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40vh; /* Height set to be responsive */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F8EDFA;
  background-image: url(${WorldMapPNG});
  background-size: cover;
  background-position: center;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(248, 237, 250, 0.8); /* Overlay effect */
    z-index: -1;
  }
`;

// Circle containers to hold rotating circles
const CircleContainer = styled.div`
  position: relative;
  width: 25vw;
  height: 25vw;
  max-width: 350px;
  max-height: 350px;
  border: 2px dashed #BDAEEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotate} 10s linear infinite;

   @media (max-width: 1200px) {
    width: 450px;
  height: 450px;
 }
   @media (max-width: 700px) {
    width: 300px;
  height: 300px;
 }
`;

const CircleContainer1 = styled(CircleContainer)`
  width: 30vw;
  height: 30vw;
  max-width: 440px;
  max-height: 440px;
   @media (max-width: 1200px) {
    width: 550px;
  height: 550px;
 }

   @media (max-width: 1200px) {
    width: 400px;
  height: 400px;
  border:none;
 }
`;

const CircleContainer2 = styled(CircleContainer)`
  width: 20vw;
  height: 20vw;
  max-width: 250px;
  max-height: 250px;
     @media (max-width: 1200px) {
    width: 350px;
  height: 350px;
 }
     @media (max-width: 700px) {
    width: 200px;
  height: 200px;
 }
`;

// Profile images inside rotating circles
const ProfileImage = styled.div`

width: 40px;
height: 40px;

  border-radius: 50%;
  position: absolute;
  background-size: cover;
  background-position: center;
  animation: ${rotateReverse} 10s linear infinite;
  transform: rotate(180deg); /* Apply transformation */
  @media (max-width: 1200px) {
  
  display:none;
 }
`;

const ProfileImage1 = styled(ProfileImage)`
width: 40px;
height: 40px;
  animation: ${rotateReverse1} 10s linear infinite;

  @media (max-width: 1200px) {
  
  display:block !important;
 }
`;

const ProfileImage2 = styled(ProfileImage)`
width: 40px;
height: 40px;
  animation: ${rotateReverse2} 10s linear infinite;
  // transform: rotate(180deg) !important; /* Removed the 180-degree rotation */
  @media (max-width: 1200px) {
  
  display:block !important;
 }
`;

// Purple dot inside profile image
const PurpleDot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #A720B9;
  position: absolute;
  bottom: 0;
  right: 10px;
`;

// Center container to hold the logo
const CenterContainer = styled.div`
  width: 12vw;
  height: 12vw;
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
  background:white;
  box-shadow: 0px 4px 14px rgba(134, 22, 150, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

   @media (max-width: 700px) {
  width:120px;
  height:120px;
 }
`;
const Marker = styled.img`
position: absolute;
`;
const RotatingProfileCircle = () => {
  return (
    <MainContainer>
      <Marker src={MarkerIconPNG} style={{ top: "90px", left: "20px", height: "39px", width: "26px" }} />
      <Marker src={MarkerIconPNG} style={{ top: "40px", right: "100px", height: "20px", width: "15px" }} />
      <Marker src={MarkerIconPNG} style={{ bottom: "90px", right: "20px", height: "25px", width: "20px" }} />
      <CenterContainer>
        <img src={KwikMediaWhitePNG} style={{ width: '60%', height: 'auto' }} alt="Kwik Media Logo" />
      </CenterContainer>

      <CircleContainer1>
        <ProfileImage style={{ backgroundImage: `url(${Circle11PNG})`, top: '-20px' }}>
          <PurpleDot />
        </ProfileImage>
        <ProfileImage style={{ backgroundImage: `url(${Circle12PNG})`, bottom: '-20px' }}>
          <PurpleDot />
        </ProfileImage>
        <ProfileImage style={{ backgroundImage: `url(${Circle13PNG})`, left: '-20px' }}>
          <PurpleDot />
        </ProfileImage>
        <ProfileImage style={{ backgroundImage: `url(${Circle14PNG})`, right: '-20px' }}>
          <PurpleDot />
        </ProfileImage>

        <CircleContainer>
          <ProfileImage1 style={{ backgroundImage: `url(${Circle21PNG})`, right: '-20px' }}>
            <PurpleDot />
          </ProfileImage1>
          <ProfileImage1 style={{ backgroundImage: `url(${Circle22PNG})`, left: '-20px' }}>
            <PurpleDot />
          </ProfileImage1>

          <CircleContainer2>
            <div style={{ position: "absolute", transform: "rotate(180deg)", top: '20px' }}>
              <ProfileImage2 style={{ backgroundImage: `url(${Circle31PNG})` }}>
                <PurpleDot />
              </ProfileImage2>
            </div>

            <div style={{ position: "absolute", transform: "rotate(180deg)", left: '20px' }}>
              <ProfileImage2 style={{ backgroundImage: `url(${Circle32PNG})` }}>
                <PurpleDot />
              </ProfileImage2>
            </div>
            <div style={{ position: "absolute", transform: "rotate(180deg)", right: '-16px' }}>
              <ProfileImage2 style={{ backgroundImage: `url(${Circle33PNG})` }}>
                <PurpleDot />
              </ProfileImage2>
            </div>
            <div style={{ position: "absolute", transform: "rotate(180deg)", bottom: '-16px' }}>
              <ProfileImage2 style={{ backgroundImage: `url(${Circle34PNG})` }}>
                <PurpleDot />
              </ProfileImage2>
            </div>
          </CircleContainer2>
        </CircleContainer>
      </CircleContainer1>
    </MainContainer>
  );
};

export default RotatingProfileCircle;
