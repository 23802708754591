import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Homepage from './Components/Pages/Homepage';
import Header from './Components/Templates/Header';
import Footer from './Components/Organism/Footer/Footer';


function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Homepage />} />
        </Routes>
        <Footer />
      </Router>
      {/* <Homepage /> */}

    </>
  );
}

export default App;
