import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InstaIconPNG from '../../../Assets/instablack.png'
import MetaIconPNG from '../../../Assets/metablack.png'
import AndroidIconPNG from '../../../Assets/androidIcon.png';
import IOSIconPNG from '../../../Assets/appleIcon.png';

const Footer = () => {

    const [activeSection, setActiveSection] = useState('home');

    const handleScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        // setMenuOpen(false); // Close menu after clicking (for mobile)
    };

    // Function to detect which section is currently in view
    const handleSectionChange = () => {
        const sections = ['home', 'numbers', 'about-us', 'features', 'phone-screens', 'contact-us', 'testimonials'];
        const scrollPosition = window.scrollY;

        for (let i = 0; i < sections.length; i++) {
            const section = document.getElementById(sections[i]);
            if (section) {
                const sectionTop = section.offsetTop - 100; // Offset to account for padding
                const sectionHeight = section.clientHeight;

                if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
                    setActiveSection(sections[i]);
                    break;
                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleSectionChange);
        return () => {
            window.removeEventListener('scroll', handleSectionChange);
        };
    }, []);
    return (
        <Container>
            <UpperSection>
                <Line />
                <LinkSection>
                    <SocialContainer>
                        <a>
                            <SocialIMG src={InstaIconPNG} />
                        </a>
                        <a>
                            <SocialIMG style={{ width: "34px", height: "21px" }} src={MetaIconPNG} />
                        </a>
                    </SocialContainer>

                    <HeaderLinks>
                        <Link style={{ fontWeight: "600" }}>Home
                            <div style={{ width: "20px", backgroundColor: "#A720B9", height: "2px" }}></div>
                        </Link>
                        <Link onClick={() => handleScroll('about-us')}>About Us</Link>
                        <Link onClick={() => handleScroll('features')}>Features</Link>
                        <Link onClick={() => handleScroll('contact-us')}>Contact Us</Link>
                        <Link onClick={() => handleScroll('testimonials')}>Testimonials</Link>
                    </HeaderLinks>
                </LinkSection>
                <Line />
            </UpperSection>
            <LowerSection>
                <ButtonContainer>
                    <Button onClick={() => {
                        window.open('https://play.google.com/store/search?q=kwikmedia&c=apps&hl=en', '_blank');
                    }}>
                        <img src={AndroidIconPNG} style={{ width: "30px", height: "30px", marginRight: "8px" }} />

                        For Android</Button>
                    <Button onClick={() => {
                        window.open('https://apps.apple.com/in/app/kwikmedia/id6717602527', '_blank');
                    }}>
                        <img src={IOSIconPNG} style={{ width: "25px", height: "30px", marginRight: "8px" }} />
                        For IOS</Button>
                </ButtonContainer>
                <SearchContainer>
                    <SearchBar >
                        <SearchInput placeholder='Send feedback' />
                        <SendButton >Send</SendButton>
                    </SearchBar>
                </SearchContainer>
            </LowerSection>
        </Container>
    )
}

export default Footer;
const Container = styled.div`
width:100%;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
margin-top:100px;
margin-bottom:50px;
`;

const UpperSection = styled.div`
width:80%


`;

const LinkSection = styled.div`
width:100%;
margin:30px 0px;
display:flex;

@media (max-width:1000px){
flex-direction:column-reverse;
}
`;

const SocialContainer = styled.div`
width:20%;

@media (max-width:1000px){
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:30px;
}
`;
const HeaderLinks = styled.div`
width:80%;
display:flex;
align-items: center;
justify-content: space-evenly;
@media (max-width:1000px){
flex-direction:column;
width:100%;
}

`;

const Link = styled.div`
font-family: Urbanist;
font-size: 20px;
font-weight: 400;
color:#A720B9;
cursor:pointer;
@media (max-width:1000px){
    margin-top:20px;

}
`;
const SocialIMG = styled.img`
width:20px;
height:20px;
margin-right:10px
`;
const Line = styled.div`
width:100%;
height:1px;
background-color:gray;
`;

const LowerSection = styled.div`
margin-top:30px;
display: flex;
width:80%;


@media (max-width:1000px){
 flex-direction: column-reverse;
}
`;

const ButtonContainer = styled.div`
width:50%;
display: flex;
justify-content:space-evenly;

@media (max-width:1000px){
 width:100%;
 margin-top:30px;
}
`;

const Button = styled.button`
width: 250px;
height: 56px;
border-radius: 50px ;
color:white;
border:none;
background: linear-gradient(360deg, #7A11A1 0%, #C62BC9 100%);
box-shadow: 0px 4px 14px 0px #86169680;
font-family: Urbanist;
font-size: 23px;
font-weight: 500;
cursor: pointer;
display:flex;
align-items: center;
justify-content: center;
@media (max-width:1000px){
 width:150px;
 font-size: 16px;
}
`
const SearchContainer = styled.div`
width:50%;
display: flex;
align-items:center;
justify-content:end;

@media (max-width:1000px){
 width:100%;
 justify-content:center;
}

`;

const SearchBar = styled.div`
width: 352px;
height: 56px;
padding: 8px 10px 8px 28px;
border-radius: 30px;
border: 1px solid #7E12A3;
display:flex;
align-items: center;
justify-content: space-between;
`;
const SearchInput = styled.input`
border:none;
font-family: Urbanist;
font-size: 16px;
font-weight: 500;
width:80%;
background-color:transparent;

&:focus{
outline:none;
}
`;
const SendButton = styled.button`
width: 80px;
height: 40px;
border-radius: 46px;
background: linear-gradient(360deg, #7A11A1 0%, #C62BC9 100%);
color:white;
border:none;
cursor:pointer;
`;
