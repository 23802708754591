import React from 'react'
import RotatingProfileCircle from '../Atoms/HeroSection/TestLottiefy'
import HeroSection from '../Organism/HeroSection/HeroSection'
import NumberSection from '../Organism/NumberSection/NumberSection'
import AboutUs from '../Organism/AboutUS/AboutUsSection'
import FeatureSection from '../Organism/FeatureSection/FeatureSection'
import PhoneScreens from '../Organism/PhoneScreenSection/PhoneScreens'
import ContactUs from '../Organism/ContactUs/ContactUs'
import Testimonials from '../Organism/Testimonials/Testimonials'
// import RotatingComponent from '../Atoms/HeroSection/TestLottiefy'

const Homepage = () => {
    return (
        <div>
            <div id="home">
                <HeroSection />
            </div>
            <div id="numbers">
                <NumberSection />
            </div>
            <div id="about-us">
                <AboutUs />
            </div>
            <div id="features">
                <FeatureSection />
            </div>
            <div id="phone-screens">
                <PhoneScreens />
            </div>
            <div id="contact-us">
                <ContactUs />
            </div>
            <div id="testimonials">
                <Testimonials />
            </div>
        </div>
    )
}

export default Homepage