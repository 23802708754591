import React from 'react'
import styled from 'styled-components'
import TestimonialsPNG from '../../../Assets/testimonials.png'
import StarPNG from '../../../Assets/yellowStar.png'
import TestimonialPRofilePNG from '../../../Assets/testimonialProfile2.png';
import Profile2JPEG from '../../../Assets/profile2.png';
import Profile3PNG from '../../../Assets/profile3.png';
import Profile4JPEG from '../../../Assets/profile4.png'
import Profile5PNG from '../../../Assets/profile5.png'
import { Carousel } from 'antd'

const Testimonials = () => {

  return (
    <Container>
      <LeftSection>
        <DataContainer>
          <BackGroundDiv />
          <StyledCarousel
            autoplay
            autoplaySpeed={3000}
            dots={{ className: 'custom-dots' }}
          >
            <div>
              <DataSection>
                <TestimonialText>
                  KwikMedia has completely transformed the way we manage and
                  share event photos with our clients. The platform is
                  incredibly user-friendly, and the face scan feature makes it
                  easy for clients to find their photos. It’s a game-changer for
                  our studio!
                </TestimonialText>
                <ProfileContainer>
                  <ProfileSection>
                    <Profile
                      style={{
                        backgroundImage: `url(${TestimonialPRofilePNG})`,
                      }}
                    />
                    <ProfileText style={{ marginLeft: '10px' }}>
                      Dhruv
                    </ProfileText>
                  </ProfileSection>
                  <StarContainer>
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                  </StarContainer>
                </ProfileContainer>
              </DataSection>
            </div>
            <div>
              <DataSection>
                <TestimonialText>
                  KwikMedia has streamlined our entire workflow. Sharing event
                  photos with clients has never been easier, and the face scan
                  feature is a huge hit. It’s an essential tool for any
                  professional photographer!
                </TestimonialText>
                <ProfileContainer>
                  <ProfileSection>
                    <Profile
                      style={{
                        backgroundImage: `url(${Profile2JPEG})`,
                      }}
                    />
                    <ProfileText style={{ marginLeft: '10px' }}>
                      Himanshu
                    </ProfileText>
                  </ProfileSection>
                  <StarContainer>
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                  </StarContainer>
                </ProfileContainer>
              </DataSection>
            </div>
            <div>
              <DataSection>
                <TestimonialText>
                  Our clients love how easy it is to find their photos with the
                  face scan tool. KwikMedia makes it simple for us to create and
                  manage albums, and the secure album lock feature gives us
                  peace of mind.
                </TestimonialText>
                <ProfileContainer>
                  <ProfileSection>
                    <Profile
                      style={{
                        backgroundImage: `url(${Profile3PNG})`,
                      }}
                    />
                    <ProfileText style={{ marginLeft: '10px' }}>
                      Darshan Studio
                    </ProfileText>
                  </ProfileSection>
                  <StarContainer>
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                  </StarContainer>
                </ProfileContainer>
              </DataSection>
            </div>
            <div>
              <DataSection>
                <TestimonialText>
                  KwikMedia’s platform is a game-changer for our photography
                  business. The ability to share event codes with clients has
                  made delivering photos so much faster, and our clients love
                  the favorites feature!
                </TestimonialText>
                <ProfileContainer>
                  <ProfileSection>
                    <Profile
                      style={{
                        backgroundImage: `url(${Profile4JPEG})`,
                      }}
                    />
                    <ProfileText style={{ marginLeft: '10px' }}>
                      Mukesh
                    </ProfileText>
                  </ProfileSection>
                  <StarContainer>
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                  </StarContainer>
                </ProfileContainer>
              </DataSection>
            </div>
            <div>
              <DataSection>
                <TestimonialText>
                  KwikMedia has transformed how we interact with our clients.
                  They love the ability to like and download photos directly,
                  and we love how easy it is to manage everything from one
                  platform.
                </TestimonialText>
                <ProfileContainer>
                  <ProfileSection>
                    <Profile
                      style={{
                        backgroundImage: `url(${Profile5PNG})`,
                      }}
                    />
                    <ProfileText style={{ marginLeft: '10px' }}>
                      Maulik
                    </ProfileText>
                  </ProfileSection>
                  <StarContainer>
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                    <Star src={StarPNG} />
                  </StarContainer>
                </ProfileContainer>
              </DataSection>
            </div>
          </StyledCarousel>
        </DataContainer>
      </LeftSection>
      <RightSection>
        <TestimonialsIMG src={TestimonialsPNG} />
      </RightSection>
    </Container>
  )
}

export default Testimonials

const CustomDot = styled.div`
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  margin: 0 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #a720b9;
  }
`

const Container = styled.div`
  display: flex;
  margin-top: 80px;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`

const LeftSection = styled.div`
  position: relative;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    margin-top: 100px;
    width: 100%;
    background-color: #a720b94d;
    padding: 20px 0px;
    border-radius: 30px;
  }
`

const RightSection = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TestimonialsIMG = styled.img`
  max-width: 300px;
  max-height: 400px;
`

const DataContainer = styled.div`
  position: relative;
  width: 80%;
  min-height: 300px;
  padding: 30px;
  box-shadow: 0px 9px 44px 0px #0000000d;
  border-radius: 40px;
  background-color: white;

  &.slick-dots li button {
    background-color: blue !important;

    transition: background-color 0.3s ease;

    &:hover {
      background-color: #a720b9;
    }
  }
`

const DataSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const TestimonialText = styled.div`
  font-family: Urbanist;
  font-size: 23px;
  font-weight: 400;
  line-height: 42.2px;
  text-align: left;
`

const ProfileContainer = styled.div`
  display: flex;
  padding: 30px 0px;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
  }
`

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
`

const ProfileText = styled.div`
  font-family: Urbanist;
  font-size: 27px;
  font-weight: 500;
`

const Profile = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
`

const StarContainer = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    margin-top: 20px;
  }
`

const Star = styled.img`
  height: 30px;
  width: 30px;
`

const BackGroundDiv = styled.div`
  width: 306px;
  height: 484px;
  border-radius: 40px;
  position: absolute;
  top: -60px;
  left: -40px;
  background: #a720b94d;
  z-index: -1;
  @media (max-width: 1000px) {
    display: none;
  }
`
const StyledCarousel = styled(Carousel)`
  .slick-dots {
    bottom: 10px; /* Adjust positioning as needed */
  }

  .slick-dots li button {
    background-color: #a720b94d; /* Change the dot color */
    opacity: 1; /* Ensure visibility */
    transition: background-color 0.3s ease; /* Smooth transition */
  }

  .slick-dots li.slick-active button {
    background-color: purple; /* Change the color of the active dot */
  }
`;