import React from 'react';
import styled from 'styled-components';
import KwikMediaLogo from '../../../Assets/kwikmediaLogo.png'

const KwikMediaIcon = ({ height, width }) => {
  return (
    <Container>
      <Logo style={{ height: `${height}`, width: `${width}` }} src={KwikMediaLogo} />
    </Container>
  )
}

export default KwikMediaIcon;

const Container = styled.div`

`;

const Logo = styled.img`

`;