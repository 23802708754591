import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import BackgroundPNG from '../../../Assets/contactBackground.png';
import ContactPhoneScreenPNG from '../../../Assets/contactUsScreen.png';
import ContactUsImagePNG from '../../../Assets/ContactUsLines.png'
// Define keyframes for the sliding animation
const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInFromRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const ContactUs = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,  // Trigger animation only once
    threshold: 0.1,     // Trigger when at least 10% of the component is visible
  });

  return (
    <Container>
      <img src={ContactUsImagePNG} style={{ height: "80px", width: "80px", position: "absolute", top: "4%", left: "45px" }} />
      <DataSection ref={ref}>
        <img
          className='backgroundImage'
          src={BackgroundPNG}
          style={{ zIndex: '1', position: 'absolute', width: '100%', height: '100%', top: '0' }}
          alt="Background"
        />
        <DataContainer style={{ zIndex: '2' }}>
          <LeftDataContainer inView={inView}>
            <HeadingText>Get in touch with us!</HeadingText>
            <SubText>
              We’re here to help! Whether you’re a studio looking for support or a user with inquiries, feel free to reach out to us. Our team is ready to provide the guidance you need to make the most out of KwikMedia.
            </SubText>
            <ButtonContainer>
              <a href="tel:1234567890"><Button>Contact</Button></a>
              <ContactText>+91 1234567890</ContactText>
            </ButtonContainer>
          </LeftDataContainer>
          <RightContainer inView={inView}>
            <HeadingTextResponsive>Get in touch with us!</HeadingTextResponsive>
            <img
              src={ContactPhoneScreenPNG}
              style={{ maxWidth: '300px', height: 'auto', width: '90%' }}
              alt="Contact Phone Screen"
            />
          </RightContainer>
        </DataContainer>
      </DataSection>
    </Container>
  );
};

export default ContactUs;

const Container = styled.div`
  width: 100%;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
position: relative;
  @media (max-width: 1000px) {
    padding-top: 40px;
  }
`;

const DataSection = styled.div`
  position: relative;
  width: 100%;
  height: 48vh;
  border-radius: 70px;

  @media (max-width: 1000px) {
    .backgroundImage {
      display: none;
    }
    height: auto;
    background: linear-gradient(360deg, #7A11A1 0%, #C62BC9 100%);
    width: 90%;
  }
`;

const DataContainer = styled.div`
  display: flex;
  width: 97%;
  margin-left: 30px;
  z-index: 2;
  position: absolute;
  height: 100%;

  @media (max-width: 1000px) {
    position: relative;
    flex-direction: column-reverse;
    margin-left: 0;
  }
`;

const LeftDataContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;

  ${(props) =>
    props.inView
      ? css`
          animation: ${slideInFromLeft} 1s ease-out forwards; // Added forwards to retain end state
        `
      : css`
          opacity: 0; // Ensure it's hidden when not in view
        `}

  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
    padding-bottom: 30px;
  }
`;

const RightContainer = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${(props) =>
    props.inView
      ? css`
          animation: ${slideInFromRight} 1s ease-out forwards; // Added forwards to retain end state
        `
      : css`
          opacity: 0; // Ensure it's hidden when not in view
        `}

  @media (max-width: 1000px) {
    width: 100%;
    padding: 40px 0px;
  }
`;

const HeadingTextResponsive = styled.div`
  font-family: Urbanist;
  font-size: 36px;
  font-weight: 600;
  color: white;
  margin-bottom: 20px;

  @media (min-width: 1000px) {
    display: none;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

const HeadingText = styled.div`
  font-family: Urbanist;
  font-size: 36px;
  font-weight: 600;
  color: white;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const SubText = styled.div`
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 600;
  color: white;

  @media (max-width: 1000px) {
    max-width: 90%;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  margin-left: 40px;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    margin-left: 0px;
  }
`;

const Button = styled.button`
  width: 206px;
  height: 59px;
  color: #9A1CB1;
  font-family: Urbanist;
  font-size: 22px;
  font-weight: 600;
  line-height: 40.37px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  @media (max-width: 1000px) {
    margin-top: 20px;
  }
`;

const ContactText = styled.div`
  font-family: Urbanist;
  font-size: 22px;
  font-weight: 600;
  color: white;
  margin-left: 10px;
`;
