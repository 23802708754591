import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Screen1PNG from '../../../Assets/screen1.png';
import Screen2PNG from '../../../Assets/screen2.png';
import Screen3PNG from '../../../Assets/screen3.png';
import Screen4PNG from '../../../Assets/screen4.png';
import Screen5PNG from '../../../Assets/screen5.png';
import { Carousel } from 'antd';


// Styled component to hold all the screens
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #F8EDFA;
  overflow: hidden;
  position: relative;

  @media (max-width: 1000px){
    display: block;
    height:auto;
  }
`;

// Styled component for each phone screen
const PhoneWrapper = styled.div`
  display: flex;
  position: relative;
  left:-110px;
  justify-content: center;
  align-items: center;
  @media (max-width:1000px){
  display:none;
  }
`;

const Image = styled.img`
  width: 250px;
  height: 500px;
  border-radius: 30px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  transition: all 1s ease;
  opacity: 1;
  transform: ${({ scale }) => `scale(${scale})`};
  z-index: ${({ zIndex }) => zIndex};
  left: ${({ left }) => left};

  /* Media queries for responsiveness */
  @media (max-width: 1200px) {
    width: 200px;
    height: 400px;
  }

  @media (max-width: 900px) {
    width: 150px;
    height: 300px;
  }

  @media (max-width: 600px) {
    width: 120px;
    height: 240px;
  }
`;
const CarousalSection = styled.div`
padding-top:40px;
@media (min-width:1000px){
    display:none;
}
`;


const StyledCarousel = styled(Carousel)`
  .slick-dots {
    bottom: 10px; /* Adjust positioning as needed */
  }

  .slick-dots li button {
    background-color: pink; /* Change the dot color */
    opacity: 1; /* Ensure visibility */
    transition: background-color 0.3s ease; /* Smooth transition */
  }

  .slick-dots li.slick-active button {
    background-color: purple; /* Change the color of the active dot */
  }
`;

// Positions array for desktop
const desktopPositions = [
    { left: '-350px', scale: 0.7, zIndex: 1 },
    { left: '-200px', scale: 1, zIndex: 2 },
    { left: '0px', scale: 1.3, zIndex: 3 },
    { left: '200px', scale: 1, zIndex: 2 },
    { left: '350px', scale: 0.7, zIndex: 1 }
];

// Positions array for smaller screens (e.g., mobile)
const mobilePositions = [
    { left: '-150px', scale: 0.6, zIndex: 1 },
    { left: '-80px', scale: 0.9, zIndex: 2 },
    { left: '0px', scale: 1.1, zIndex: 3 },
    { left: '80px', scale: 0.9, zIndex: 2 },
    { left: '150px', scale: 0.6, zIndex: 1 }
];

const contentStyle = {
    height: '400px',  // Adjust as needed
    color: '#fff',
    lineHeight: '400px',
    textAlign: 'center',
    background: '#364d79',
};
const PhoneScreens = () => {
    const [activeIndex, setActiveIndex] = useState(2); // Start with the center image as active
    const [images, setImages] = useState([Screen1PNG, Screen2PNG, Screen3PNG, Screen4PNG, Screen5PNG]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600); // Track if the screen is mobile

    // Rotate images every 3 seconds (or desired interval)
    useEffect(() => {
        const interval = setInterval(() => {
            rotateImages();
        }, 3000); // Adjust the speed of the transition here

        return () => clearInterval(interval); // Cleanup on unmount
    }, [images]);

    // Function to rotate images (move positions)
    const rotateImages = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % images.length); // Increment index and reset at the end
    };

    // Function to detect screen resize and update state
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600); // Update isMobile based on window width
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup event listener on unmount
        };
    }, []);

    // Use mobile or desktop positions based on screen size
    const positions = isMobile ? mobilePositions : desktopPositions;

    return (<>

        <Container>
            <PhoneWrapper>
                {images.map((image, index) => {
                    // Calculate the position for each image based on the index
                    const positionIndex = (index - activeIndex + images.length) % images.length;
                    const { left, scale, zIndex } = positions[positionIndex];

                    return (
                        <Image
                            key={index}
                            src={image}
                            alt={`Screen ${index + 1}`}
                            left={left}
                            scale={scale}
                            zIndex={zIndex}
                        />
                    );
                })}
            </PhoneWrapper>
            <CarousalSection >
                <StyledCarousel autoplay autoplaySpeed={3000}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ width: "100%", minHeight: "680px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={Screen5PNG} alt="Slide 1" style={{ maxWidth: "300px", height: 'auto' }} />
                        </div>
                    </div>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ width: "100%", minHeight: "680px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={Screen2PNG} alt="Slide 1" style={{ maxWidth: "300px", height: 'auto' }} />
                        </div>
                    </div>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <div style={{ width: "100%", minHeight: "680px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={Screen1PNG} alt="Slide 1" style={{ maxWidth: "300px", height: 'auto' }} />
                        </div>
                    </div>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <div style={{ width: "100%", minHeight: "680px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={Screen3PNG} alt="Slide 1" style={{ maxWidth: "300px", height: 'auto' }} />
                        </div>
                    </div>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <div style={{ width: "100%", minHeight: "680px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={Screen4PNG} alt="Slide 1" style={{ maxWidth: "300px", height: 'auto' }} />
                        </div>
                    </div>

                </StyledCarousel>
            </CarousalSection>

        </Container>

    </>
    );
};

export default PhoneScreens;
