import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useInView } from 'react-intersection-observer';

const NumberSection = () => {
  const [userCount, setUserCount] = useState(0);
  const [studioCount, setStudioCount] = useState(0);
  const [albumCount, setAlbumCount] = useState(0);
  const [subscriber, setSubScriber] = useState(0);

  // useInView hook to track if the component is in view
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once when it enters the view
    threshold: 0.1, // Trigger when at least 10% of the component is visible
    rootMargin: '0px 0px -50px 0px', // Adjusts when the component comes into view
  });

  // Function to handle counting effect
  const animateNumbers = (target, setState) => {
    const duration = 2000; // 2 seconds for the entire animation
    const increment = target / (duration / 100); // Increment for each frame

    let current = 0;
    const step = () => {
      current += increment;
      if (current >= target) {
        current = target;
      }
      setState(Math.floor(current)); // Set the count to nearest integer
      if (current < target) {
        requestAnimationFrame(step); // Continue animation
      }
    };
    requestAnimationFrame(step);
  };

  // Trigger animation when the component comes into view
  if (inView && userCount === 0) {
    animateNumbers(5000, setUserCount); // Animate numbers up to 3K
  }
  if (inView && subscriber === 0) {
    animateNumbers(2000, setSubScriber); // Animate numbers up to 3K
  }
  if (inView && albumCount === 0) {
    animateNumbers(3000, setAlbumCount); // Animate numbers up to 3K
  }
  if (inView && studioCount === 0) {
    animateNumbers(1000, setStudioCount); // Animate numbers up to 3K
  }

  // Convert number to "K" format
  const formatNumber = (num) => {
    if (num >= 1000) {
      return `${Math.floor(num / 1000)}K`;
    }
    return num;
  };

  return (
    <Container ref={ref}>
      <DataSection>
        <Data>
          <DataText>{formatNumber(userCount)}+</DataText>
          <DataSubText>Users</DataSubText>
        </Data>
        <ColorDot style={{ background: "#DED6F7" }} />
      </DataSection>
      <HorizontalLine />
      <DataSection>
        <ColorDot style={{ background: "#A720B9" }} />
        <Data>
          <DataText>{formatNumber(studioCount)}+</DataText>
          <DataSubText>Studio</DataSubText>
        </Data>
      </DataSection>
      <HorizontalLine />
      <DataSection>
        <Data>
          <DataText>{formatNumber(albumCount)}+</DataText>
          <DataSubText>Albums</DataSubText>
        </Data>
        <ColorDot style={{ background: "#DED6F7" }} />
      </DataSection>
      <HorizontalLine />
      <DataSection>
        <ColorDot style={{ background: "#A720B9" }} />
        <Data>
          <DataText>{formatNumber(subscriber)}+</DataText>
          <DataSubText>Subscribers</DataSubText>
        </Data>
      </DataSection>
    </Container>
  );
};

export default NumberSection;

// Keyframes for optional fade-in effect
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

// Responsive container
const Container = styled.div`
  display: flex;
  margin-top: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;  // Allows wrapping on smaller screens
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;  // Stack the sections vertically
  }
`;

const DataSection = styled.div`
  width: 22%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    width: 90%;  // Full width on smaller screens
  }
`;

const ColorDot = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const Data = styled.div`
  animation: ${fadeIn} 2s ease;  // Optional fade-in animation
`;

const DataText = styled.div`
  font-family: Urbanist;
  font-size: 36px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 28px;  // Smaller font for mobile
  }
`;

const DataSubText = styled.div`
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 16px;  // Smaller font for mobile
  }
`;

const HorizontalLine = styled.div`
  height: 40px;
  width: 2px;
  background-color: #A720B9;

  @media (max-width: 768px) {
    height: 2px;
    width: 80%;  // Horizontal line on smaller screens
  }
`;
