import React from 'react';
import styled from 'styled-components';
import StarPNG from '../../../Assets/star.png'
import AndroidIconPNG from '../../../Assets/androidIcon.png';
import IOSIconPNG from '../../../Assets/appleIcon.png';

const LeftSection = () => {
    return (
        <Container>
            <UpperTextSection>
                <HeadingText>
                    <StarImage src={StarPNG} style={{ top: "-30px" }} />

                    <MainHeadingText>Kwik Media app </MainHeadingText>
                    <span style={{ position: "relative" }}>
                        <LinesSection>
                            <Line style={{ position: "absolute", top: "-6px", left: "1px", width: "20px", transform: "rotate(104deg)" }} />
                            <Line style={{ position: "absolute", top: "0px", left: "15px", width: "35px", transform: "rotate(-45deg)" }} />
                            <Line style={{ position: "absolute", top: "25px", left: "25px", width: "20px", transform: "rotate(-10deg)" }} />
                        </LinesSection>
                        is the most reputable
                    </span> app for photo sharing and discovery for studios and users<MainHeadingText>!</MainHeadingText>

                </HeadingText>
                <SubHeading>
                    <SubHeadingText>
                        With KwikMedia, studios create stunning albums while users can like, download, and find their photos in seconds using our face scan technology.
                    </SubHeadingText>
                    <StarImage style={{ height: "20px", width: '20px', right: "46px", bottom: "-10px" }} src={StarPNG} />
                </SubHeading>
            </UpperTextSection>
            <ButtonContainer>

                <Button onClick={() => {
                    window.open('https://play.google.com/store/search?q=kwikmedia&c=apps&hl=en', '_blank');
                }}>
                    <img src={AndroidIconPNG} style={{ width: "30px", height: "30px", marginRight: "8px" }} />
                    Download Android

                </Button>
                <Button style={{ marginLeft: "30px" }} onClick={() => {
                    window.open('https://apps.apple.com/in/app/kwikmedia/id6717602527', '_blank');
                }}>
                    <img src={IOSIconPNG} style={{ width: "25px", height: "30px", marginRight: "8px" }} />
                    Download IOS

                </Button>
            </ButtonContainer>
        </Container>
    )
}

export default LeftSection;

const Container = styled.div`
    width: 60%;
    max-width: 565px;

    @media (max-width: 1200px) {
        width: 90%;
    }

    @media (max-width: 768px) {
        width: 99%;
        max-width: 100%;
        padding: 0 15px;
    }
`;

const UpperTextSection = styled.div`
    @media (max-width: 768px) {
        text-align: center;
    }
`;

const MainHeadingText = styled.span`
    font-family: Urbanist;
    font-size: 48px;
    font-weight: 700;
    color: #A720B9;
    text-align: left;

    @media (max-width: 768px) {
        font-size: 36px;
    }

    @media (max-width: 480px) {
        font-size: 28px;
    }
`;

const HeadingText = styled.div`
    position: relative;
    font-family: Urbanist;
    font-size: 40px;
    font-weight: 600;
    line-height: 57.2px;
    text-align: left;
    width: 100%;

    @media (max-width: 768px) {
        font-size: 32px;
        text-align: center;
    }

    @media (max-width: 480px) {
        font-size: 24px;
    }
`;

const StarImage = styled.img`
    height: 30px;
    width: 30px;
    position: absolute;

    @media (max-width: 768px) {
        height: 25px;
        width: 25px;
    }

    @media (max-width: 480px) {
        height: 20px;
        width: 20px;
    }
`;

const LinesSection = styled.div`
    position: absolute;
    top: -30px;
    right: -91px;

    @media (max-width: 768px) {
        display: none;
    }
`;

const Line = styled.div`
    height: 3px;
    background: #A720B9;
    border-radius: 40px;
`;

const SubHeading = styled.div`
    position: relative;
`;

const SubHeadingText = styled.p`
    font-family: Urbanist;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.6px;
    text-align: left;

    @media (max-width: 768px) {
        font-size: 18px;
        text-align: center;
    }

    @media (max-width: 480px) {
        font-size: 16px;
    }
`;

const ButtonContainer = styled.div`
width:100%;
display:flex;
padding-top:40px;
`;


const Button = styled.button`
background: linear-gradient(360deg, #7A11A1 0%, #C62BC9 100%);
box-shadow: 0px 4px 14px 0px #86169680;
width: 325px;
height: 67px;
font-family: Urbanist;
font-size: 20px;
font-weight: 600;
line-height: 28.6px;
text-align: center;
color:white;
border:none;
cursor:pointer;
border-radius: 46px;

display:flex;
align-items:center;
justify-content:center;
`;