import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import KwikMediaIcon from '../Atoms/Header/KwikMediaIcon';
// import KwikMediaText from '../Atoms/Header/KwikMediaText';
import InstaPNG from '../../Assets/instablack.png';
import MetaPNG from '../../Assets/metablack.png';
import { FaBars, FaTimes } from 'react-icons/fa'; // Icons for menu burger and close

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setMenuOpen(false); // Close menu after clicking (for mobile)
  };

  // Function to detect which section is currently in view
  const handleSectionChange = () => {
    const sections = ['home', 'numbers', 'about-us', 'features', 'phone-screens', 'contact-us', 'testimonials'];
    const scrollPosition = window.scrollY;

    for (let i = 0; i < sections.length; i++) {
      const section = document.getElementById(sections[i]);
      if (section) {
        const sectionTop = section.offsetTop - 100; // Offset to account for padding
        const sectionHeight = section.clientHeight;

        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          setActiveSection(sections[i]);
          break;
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleSectionChange);
    return () => {
      window.removeEventListener('scroll', handleSectionChange);
    };
  }, []);
  return (
    <Container>
      <Section1>
        <KwikMediaIcon height={'130px'} width={'130px'} />
      </Section1>
      <MenuBurger onClick={toggleMenu}>
        {menuOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
      </MenuBurger>
      <MenuSection menuOpen={menuOpen}>
        <MenuItemsList>
          <MenuItem
            style={{ color: "#A720B9", fontWeight: "600" }}
            active={activeSection === 'home'}
            onClick={() => handleScroll('home')}
          >
            Home
            <div style={{ width: "20px", backgroundColor: "#A720B9", height: "2px" }}></div>
          </MenuItem>
          <MenuItem
            active={activeSection === 'about-us'}
            onClick={() => handleScroll('about-us')}
          >
            About Us
          </MenuItem>
          <MenuItem
            active={activeSection === 'features'}
            onClick={() => handleScroll('features')}
          >
            Features
          </MenuItem>
          <MenuItem
            active={activeSection === 'contact-us'}
            onClick={() => handleScroll('contact-us')}
          >
            Contact Us
          </MenuItem>
          <MenuItem
            active={activeSection === 'testimonials'}
            onClick={() => handleScroll('testimonials')}
          >
            Testimonials
          </MenuItem>
        </MenuItemsList>
      </MenuSection>
      <Section3>
        <SocialIcon src={MetaPNG} style={{ height: '20px', width: '40px' }} />
        <SocialIcon src={InstaPNG} style={{ height: '20px', width: '20px' }} />
        <SocialText>kwik_media</SocialText>
      </Section3>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100px;
  position: relative;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Section1 = styled.div`
  display: flex;
  align-items: center;
width:25%;
justify-content: center;
  @media (max-width: 768px) {
    position: absolute;
    left: 50%;
    width:100%;
    transform: translateX(-50%);
  }
`;

const MenuBurger = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1001;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MenuSection = styled.div`
  width: 50%;

  @media (max-width: 1000px) {
     width: 70%;
  position: absolute; // Position the menu absolutely
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 100;
  background-color: #ead2edfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  transform: translateX(${(props) => (props.menuOpen ? '0' : '100%')}); // Animation on open/close
  opacity: ${(props) => (props.menuOpen ? '1' : '0')}; // Fade effect
  padding-top: 90px;
  border-radius:40px;
  
 
  }
`;

const Section3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MenuItem = styled.div`
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  margin: 10px 0;
  cursor: pointer;
//   color: #A720B9;
color:black;
`;

const SocialIcon = styled.img`
  margin-right: 10px;
`;

const SocialText = styled.div`
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 500;
`;

const MenuItemsList = styled.div`
  display: flex;
  
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  gap: 15px;

   @media (max-width: 768px) {
    
    flex-direction: column;
  }
`;

const SectionResponsiveSection = styled.div`
display: none;

  @media (max-width: 768px) { 
 display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 30px;
  margin-top:40px;
  }
`;